<template>
    <div id="collection">
        <van-row type="flex"  justify="center" class="content">
            <van-col span="24" ref="content">

                <div class="banner">
                    <img :src="empData.coverImg" />
                    <div>
                        <img :src="empData.headImg" class="header"/>
                        <p class="name">{{empData.realName}}</p>
                    </div>
                </div>


                <masonry :cols="4" :gutter="10" class="waterfall03">
                    <div v-for="(item, index) in collectionList" :key="index" @click="jump(item.productId)" class="listBox">
                        <img :src="item.thumbinail" alt="">
                        <div class="introduce">
                            <div class="item-name">
                                <h4>{{item.productName}}</h4>
                                <p>
                                    <i class="iconfont icon-chakan"></i><span>5689</span>
                                    <i class="iconfont icon-xingxing"></i><span>5689</span>
                                </p>
                            </div>
                            <div class="item-footer">
                                <i class="iconfont icon-shoucang1" @click.stop="cancelCollection(item.productId, index)"></i>
                            </div>
                        </div>
                        <i class="iconfont icon-beizhu remarks" @click.stop="lockRemark(item.favId, item.productId)"></i>
                    </div>
                </masonry>
                
            </van-col>
        </van-row>
        <van-popup v-model="lockRemarkModal" class="lockRemarkModal">
            <div class="header">
                <h4>备注</h4>
                <!-- <i class="iconfont icon-guanbi" @click="lockRemarkModal = false"></i> -->
            </div>

            <div class="body">
                <div v-for="(item, index) in favRemarkData" :key="index">
                    <p>{{item.label}}</p>
                    <p>{{item.contact}}</p>
                    <i class="iconfont icon-bianji" @click="editRemark(index)"></i>
                    <i class="iconfont icon-shanchu1" @click="delModal = true; delIndex = index"></i>
                </div>

                <div v-if="favRemarkData.length == 0" class="tips">还没有备注信息，点击添加按钮添加备注</div>
            </div>
            <div class="add">
                <i class="iconfont icon-jia" @click="onCollectionModal"></i>
            </div>
        </van-popup>

        <van-popup v-model="delModal" class="modal delModal">
            <div class="modalHeader">
                <h4>删除备注信息</h4>
                <!-- <i class="iconfont icon-guanbi" @click="delModal = false"></i> -->
            </div>

            <div class="modalBody">
                <p>确认删除此条备注信息吗？</p>
            </div>

            <div class="modalFooter">
                <button class="confirm" @click="confirmDel">确认</button>
                <button class="cancel" @click="delModal = false">取消</button>
            </div>
        </van-popup>

        <van-popup v-model="editModal" class="modal editModal">
            <div class="modalHeader">
                <h4>修改备注信息</h4>
                <!-- <i class="iconfont icon-guanbi" @click="editModal = false"></i> -->
            </div>

            <div class="modalBody">
                <input ref="editInput" type="text" value="">
            </div>

            <div class="modalFooter">
                <button class="confirm" @click="conformEdit">确认</button>
                <button class="cancel" @click="editModal = false">取消</button>
            </div>
        </van-popup>

        <van-popup v-model="collectionModal" class="addCollection">
            <AddCollection ref="collection" :isAddRemark="true" :productId="productId" :productIndex="0" @confirmAddRemark="confirmAddRemark" @confirmCollection="confirmCollection"/>
        </van-popup>

        <van-popup v-model="cancelCollectionModal" class="modal cancelModal">
            <div class="modalHeader">
                <h4>取消收藏</h4>
                <!-- <i class="iconfont icon-guanbi" @click="cancelCollectionModal = false"></i> -->
            </div>
            <div class="modalBody">
                <p>确认取消收藏吗？</p>
            </div>
            <div class="modalFooter">
                <button class="confirm" @click="confirmCancelCollection(cancelCollectionData)">确认</button>
                <button class="cancel" @click="cancelCollectionModal = false">取消</button>
            </div>
        </van-popup>

        <rotate-square5 v-if="isLoading" class="loading"></rotate-square5>
    </div>
</template>

<script>
import {getFavList, getEmpInfo, removeFav, getFavRemark, updateFav} from '../api/index'
import AddCollection from './addCollection'
import {RotateSquare5} from 'vue-loading-spinner'
export default {
    data () {
        return {
            token: '',
            collectionList: '', //收藏夹数据
            isLoading:false,
            loadingDotStyle: { //loading动画内小圆点的样式对象，自定义其样式
                background: '#3CA8FE',
            },
            empData:'', //员工数据
            lockRemarkModal: false,
            favRemarkData: '', //收藏产品已有的备注信息
            collectionModal: false,
            productId: '', //当前选择的产品id
            favId: '', //当前选择的产品收藏id
            delModal: false, // 删除弹窗
            delIndex: '', //删除的备注定位
            editModal: false, //修改备注信息
            cancelCollectionModal:false,
            cancelCollectionData: { //取消收藏参数
                id: '',
                index: ''
            },
            position: '',
        }
    },
    mounted () {
        
    },
    activated () {
        const {token, position} = this.$route.query;
        position && (this.position = position);
        this.token = token;
        this.favList();
        this.empInfo();
    },
    components:{AddCollection, RotateSquare5},
    computed: {
        width () {
            return this.$refs.content.$el.clientWidth / 5 - 6;
        }
    },
    methods: {

        //获取收藏夹列表
        async favList () {
            const {token} = this;
            this.isLoading = true;
            let res = await getFavList(token);
            const {status, data} = res;
            if (status == 0) {
                this.isLoading = false;
                this.collectionList = data.list;
            }
        },

        //获取员工信息
        async empInfo () {
            const {token} = this;
            let res = await getEmpInfo(token);
            this.empData = res.data;
        },

        //取消收藏弹窗
        cancelCollection (id, index) {
            this.cancelCollectionModal = true;
            this.cancelCollectionData.id = id;
            this.cancelCollectionData.index = index;
        },

        //取消收藏
        async confirmCancelCollection ({id, index}) {
            const {token} = this;
            let data = {token, id};
            let res = await removeFav(data);
            console.log(res);
            if (res.status == 0) {
                this.collectionList.splice(index, 1);
                this.cancelCollectionModal = false;
            }
        },

        //查看产品备注
        async lockRemark (favId, productId) {
            const {token} = this;
            this.productId = productId;
            this.favId = favId;
            let arr = {token, favId};
            let res = await getFavRemark(arr);
            const {data, status} = res;
            if (status == 0) {
                this.favRemarkData = data.list;
                this.lockRemarkModal = true;
                console.log(this.favRemarkData);
            }
        },

        //添加备注
        async confirmAddRemark (data) {
            console.log(data);
            const {model, cheats} = data;
            const {favId, productId, token, favRemarkData} = this;
            this.favRemarkData.push(...cheats);
            let arr = {token, favId, cheats:favRemarkData};
            let res = await updateFav(arr);
            console.log(res);
            if (res.status == 0) {
                this.lockRemark(favId, productId);
                this.collectionModal = false;
                this.$toast('添加成功');
            }
        },

        //删除备注
        async confirmDel () {
            const {delIndex, favRemarkData, favId, token} = this;
            this.favRemarkData.splice(delIndex, 1);
            let data = {token, favId, cheats:favRemarkData};
            let res = await updateFav(data);
            console.log(res);
            if (res.status == 0) {
                this.delModal = false;
                this.$toast('删除成功');
            }
        },

        editRemark (index) {
            this.editModal = true;
            this.delIndex = index;
            setTimeout(() => {
                this.$refs.editInput.value = this.favRemarkData[index].contact;
            })
        },

        //修改备注
        async conformEdit () {
            const {$refs, delIndex, favRemarkData, favId, token} = this;
            this.favRemarkData[delIndex].contact = $refs.editInput.value;
            let data = {token, favId, cheats:favRemarkData};
            let res = await updateFav(data);
            if (res.status == 0) {
                this.editModal = false;
                this.$toast('修改成功');
            }
        },
        
        onCollectionModal () {
            this.collectionModal = true;
            setTimeout(() => {
                this.$refs.collection.restTypes();
            })
        },

        confirmCollection (data) {
            const {model} = data;
            this.collectionModal = model;
        },

        jump(id) {
            const {token, $router, $route, position} = this;
            $router.push({name: 'productDetails', query:{token, id, position, vertical: $route.query.vertical}});
           
        },
    }
}
</script>

<style lang="less" src="@/style/collection.less">

</style>