import { render, staticRenderFns } from "./collection.vue?vue&type=template&id=6cd4346c&"
import script from "./collection.vue?vue&type=script&lang=js&"
export * from "./collection.vue?vue&type=script&lang=js&"
import style0 from "@/style/collection.less?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports